import React from 'react'
import styled from 'styled-components'
import MaxWidthWrapper from '../components/MaxWidthWrapper/MaxWidthWrapper'
import StandardLayout from '../templates/StandardBlogPost'

const StyledHeading = styled.h1`
  color: var(--color-secondary);
`

export interface AboutPageProps {}

const AboutPage: React.FC<AboutPageProps> = () => {
  return (
    <StandardLayout>
      <MaxWidthWrapper>
        <StyledHeading>Hello World!</StyledHeading>
      </MaxWidthWrapper>
    </StandardLayout>
  )
}

export default AboutPage
